<template>
  <HeaderPages />
  <section class="section_header_full_pages">
    <div class="waves_full_pages"></div>
    <div class="container_text_full_pages">
      <h1 class="title_home">САЙТЫ И СЕРВИСЫ</h1>
      <div class="box_button_brifing_full_pages">
        <div class="box_button_v2">
          <button class="button_v1">Оставить заявку</button>
        </div>
      </div>
      <p class="medium_text text_position_services">
        Создаем удобные и эффективные сайты, которые нравятся пользователям и
        хорошо продвигаются в поисковых системах.
      </p>
    </div>
  </section>
  <section class="section_advantages_services">
    <div class="container_advantages">
      <h2 class="medium_text text_position_advantages_services">
        Глубокая аналитика
      </h2>
      <img src="@/assets/background/analytics.png" class="img_advantages" />
      <p
        class="font_1_400_Kreadon_Regular text_position_advantages_services_v2"
      >
        Исследуем рынок, анализируем конкурентов, изучаем аудиторию и выявляем
        точки роста.
      </p>
    </div>
    <div class="container_advantages">
      <h2 class="medium_text text_position_advantages_services">
        Гибкие методологии<br />Agile
      </h2>
      <img src="@/assets/background/flexible.png" class="img_advantages" />
      <p
        class="font_1_400_Kreadon_Regular text_position_advantages_services_v2"
      >
        Прозрачные и гибкие подходы гарантируют адаптацию к изменяющимся
        требованиям.
      </p>
    </div>
    <div class="container_advantages">
      <h2 class="medium_text text_position_advantages_services">
        Управление вниманием
      </h2>
      <img src="@/assets/background/control.png" class="img_advantages" />
      <p
        class="font_1_400_Kreadon_Regular text_position_advantages_services_v2"
      >
        Создаём интерфейсы, которые удерживают внимание и конвертируют
        пользователей.
      </p>
    </div>
    <div class="container_advantages">
      <h2 class="medium_text text_position_advantages_services">
        Сокращение<br />time to market
      </h2>
      <img src="@/assets/background/reduction.png" class="img_advantages" />
      <p
        class="font_1_400_Kreadon_Regular text_position_advantages_services_v2"
      >
        Минимизируем время от идеи до запуска, обеспечивая высокое качество.
      </p>
    </div>
  </section>
  <section class="section_solutions">
    <h2 class="title_services_design color_violet">РЕШЕНИЯ</h2>
    <div class="container_solutions">
      <div
        v-for="(solution, index) in solutions"
        :key="index"
        @click="solution.text && toggleModal(index)"
        :ref="'box_solutions_' + index"
        class="box_solutions"
      >
        <div class="block_solutions">
          <p class="font_1_6_400_Kreadon_Regular">{{ solution.title }}</p>
          <!-- Стрелка отображается только если есть текст -->
          <img
            v-if="solution.text"
            src="@/assets/elements/arrow_href.svg"
            class="arrow_href_img"
          />
        </div>
        <!-- Текстовый блок, который может быть скрыт, если текст отсутствует -->
        <div :ref="'block_max_text_' + index" class="block_max_text">
          <p
            v-if="solution.text"
            class="font_1_400_Kreadon_Regular color_violet"
          >
            {{ solution.text }}
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="section_development_technologies">
    <h2 class="title_services_design color_violet">МЫ ИСПОЛЬЗУЕМ ТЕХНОЛОГИИ</h2>
    <div class="container_development_technologies">
      <div class="box_development_technologies">
        <ul
          class="list_services subtext_services_design white_development_technologies"
        >
          <li>PHP</li>
          <li>1С-Битрикс</li>
          <li>Битрикс24</li>
          <li>CI/CD</li>
        </ul>
        <ul
          class="list_services subtext_services_design white_development_technologies"
        >
          <li>Redis</li>
          <li>RabbitMQ</li>
          <li>Highload</li>
          <li>Git</li>
        </ul>
        <ul
          class="list_services subtext_services_design white_development_technologies"
        >
          <li>Composer</li>
          <li>Migrations</li>
          <li>ООП</li>
          <li>Laravel</li>
        </ul>
      </div>
      <div class="box_development_technologies">
        <ul
          class="list_services subtext_services_design white_development_technologies"
        >
          <li>HTML5</li>
          <li>CSS3 (Sass/SCSS)</li>
          <li>БЭМ</li>
          <li>JavaScript (ES6+)</li>
        </ul>
        <ul
          class="list_services subtext_services_design white_development_technologies"
        >
          <li>React</li>
          <li>Vue</li>
          <li>Webpack</li>
          <li>Gulp</li>
        </ul>
        <ul
          class="list_services subtext_services_design white_development_technologies"
        >
          <li>NPM</li>
          <li>SVG</li>
          <li>Canvas</li>
          <li>WebGL</li>
        </ul>
      </div>
    </div>
  </section>

  <SectionProjectsMain />
  <FooterPages />
</template>
<script>
import HeaderPages from "../permanent/HeaderPages.vue";
import FooterPages from "../permanent/FooterPages.vue";
import SectionProjectsMain from "../ui/SectionProjectsMain.vue";
import { useHead } from "@vueuse/head";

export default {
  components: { HeaderPages, SectionProjectsMain, FooterPages },
  data() {
    return {
      solutions: [
        {
          title: "Корпоративные сайты",
          text: `Разработка корпоративных сайтов с учётом всех требований бизнеса.
           Мы создаём платформы, которые помогают укрепить имидж компании,
           наладить коммуникации с партнёрами и клиентами, а также интегрировать
           современные решения, такие как CRM и аналитические системы.`,
        },
        {
          title: "Онлайн-сервисы",
          text: `Проектирование и разработка онлайн-сервисов: личные кабинеты,
           платформы для автоматизации процессов, сервисы заказа и управления.
           Мы создаём удобные и безопасные решения для любых задач.`,
        },
        {
          title: "Е-commerce",
          text: `Комплексное создание интернет-магазинов и e-commerce платформ.
           Мы обеспечиваем функциональность, удобство для пользователей
           и интеграцию с популярными платёжными системами, а также
           оптимизацию для поисковых систем.`,
        },
        {
          title: "Порталы",
          text: `Создание масштабных порталов с уникальной архитектурой.
           Мы проектируем сложные системы, которые включают базы данных,
           инструменты аналитики и высокую производительность.`,
        },
        {
          title: "Карьерные сайты",
          text: `Разработка сайтов для HR-целей: вакансии, личные кабинеты сотрудников,
           автоматизация подбора персонала. Мы создаём платформы, которые
           улучшают процессы рекрутинга и укрепляют бренд работодателя.`,
        },
        {
          title: "Системы сайтов",
          text: `Создание многоуровневых систем сайтов для холдингов и корпораций.
           Мы разрабатываем комплексные решения, которые объединяют дочерние
           компании в единую экосистему.`,
        },
        {
          title: "Продуктовые каталоги",
          text: `Разработка сайтов-каталогов для презентации товаров и услуг.
           Мы обеспечиваем удобную навигацию, фильтры и функциональность,
           которые помогают клиентам легко находить нужные продукты.`,
        },
        {
          title: "Промо-сайты",
          text: `Создание промо-сайтов для презентации новых продуктов, услуг или
           мероприятий. Мы делаем акцент на визуальной привлекательности
           и высокой конверсии.`,
        },
        {
          title: "No-code",
          text: `Разработка no-code решений для быстрого запуска MVP или небольших
           проектов. Мы используем платформы Tilda и WordPress, чтобы
           предоставить клиентам быстрые и функциональные результаты.`,
        },
      ],
    };
  },
  methods: {
    toggleModal(index) {
      const box = this.$refs[`box_solutions_${index}`][0];
      const text = this.$refs[`block_max_text_${index}`][0];
      if (box && text) {
        box.classList.toggle("active");
        text.classList.toggle("active");
      }
    },
  },
  setup() {
    useHead({
      title:
        "Разработка сайтов и сервисов: корпоративные порталы, e-commerce, онлайн-сервисы",
      meta: [
        {
          name: "description",
          content:
            "Создание сайтов и веб-сервисов: корпоративные сайты, интернет-магазины, онлайн-платформы, карьерные сайты и промо-решения. Удобный интерфейс, высокая производительность и оптимизация для поисковых систем",
        },
      ],
    });
  },
};
</script>
<style scoped>
.container_text_full_pages {
  padding-left: 6rem;
}
.section_advantages_services {
  padding: 8rem 1rem 0rem 1rem;
}
.text_position_services {
  bottom: -0.5rem;
}
@media (max-width: 768px) {
  .text_position_services {
    bottom: -8rem;
  }
  .section_advantages_services {
    padding: 10rem 1rem 0rem 1rem;
  }
}
</style>
