<template>
  <HeaderPages />
  <section class="section_header_full_pages">
    <div class="waves_full_pages"></div>
    <div class="container_text_full_pages">
      <h1 class="title_home">
        <span class="title_home_4">КОМПЛЕКСНАЯ <br /></span>
        <span class="title_home_1 color_violet">АНАЛИТИКА И АУДИТ<br /></span>
      </h1>
      <div class="box_button_brifing_full_pages">
        <div class="box_button_v2">
          <button class="button_v1">Оставить заявку</button>
        </div>
      </div>
      <p class="medium_text text_position_services">
        Проводим всестороннюю аналитику и аудит: от UX-анализа до
        бизнес-стратегий. Помогаем выявить точки роста и улучшить ваш проект.
      </p>
    </div>
  </section>
  <section class="section_advantages_services">
    <div class="container_advantages">
      <h2 class="medium_text text_position_advantages_services">
        Комплексный подход
      </h2>
      <img src="@/assets/background/analytics.png" class="img_advantages" />
      <p
        class="font_1_400_Kreadon_Regular text_position_advantages_services_v2"
      >
        Мы исследуем все аспекты вашего проекта: от анализа пользовательского
        пути до оценки бизнес-процессов.
      </p>
    </div>
    <div class="container_advantages">
      <h2 class="medium_text text_position_advantages_services">
        Точные данные
      </h2>
      <img src="@/assets/background/flexible.png" class="img_advantages" />
      <p
        class="font_1_400_Kreadon_Regular text_position_advantages_services_v2"
      >
        Используем современные аналитические инструменты для получения
        достоверной информации о вашем бизнесе.
      </p>
    </div>
    <div class="container_advantages">
      <h2 class="medium_text text_position_advantages_services">
        Практичные рекомендации
      </h2>
      <img src="@/assets/background/control.png" class="img_advantages" />
      <p
        class="font_1_400_Kreadon_Regular text_position_advantages_services_v2"
      >
        Предлагаем понятные и применимые решения для роста эффективности вашего
        проекта.
      </p>
    </div>
    <div class="container_advantages">
      <h2 class="medium_text text_position_advantages_services">
        Повышение ROI
      </h2>
      <img src="@/assets/background/reduction.png" class="img_advantages" />
      <p
        class="font_1_400_Kreadon_Regular text_position_advantages_services_v2"
      >
        Оптимизируем процессы и стратегию, чтобы вы получали максимальную отдачу
        от инвестиций.
      </p>
    </div>
  </section>
  <section class="section_solutions">
    <h2 class="title_services_design color_violet">РЕШЕНИЯ</h2>
    <div class="container_solutions">
      <div
        v-for="(solution, index) in solutions"
        :key="index"
        @click="solution.text && toggleModal(index)"
        :ref="'box_solutions_' + index"
        class="box_solutions"
      >
        <div class="block_solutions">
          <p class="font_1_6_400_Kreadon_Regular">{{ solution.title }}</p>
          <!-- Стрелка отображается только если есть текст -->
          <img
            v-if="solution.text"
            src="@/assets/elements/arrow_href.svg"
            class="arrow_href_img"
          />
        </div>
        <!-- Текстовый блок, который может быть скрыт, если текст отсутствует -->
        <div :ref="'block_max_text_' + index" class="block_max_text">
          <p
            v-if="solution.text"
            class="font_1_400_Kreadon_Regular color_violet"
          >
            {{ solution.text }}
          </p>
        </div>
      </div>
    </div>
  </section>
  <SectionProjectsMain />
  <FooterPages />
</template>
<script>
import HeaderPages from "../permanent/HeaderPages.vue";
import FooterPages from "../permanent/FooterPages.vue";
import SectionProjectsMain from "../ui/SectionProjectsMain.vue";
import { useHead } from "@vueuse/head";

export default {
  components: { HeaderPages, SectionProjectsMain, FooterPages },
  data() {
    return {
      solutions: [
        {
          title: "UX-аналитика и юзабилити аудит",
          text: `Проводим детальный анализ взаимодействия пользователей с вашим продуктом.
           Выявляем точки фрустрации, сложности в навигации и ошибки интерфейса.
           Предоставляем рекомендации по улучшению UX, повышению удобства и
           увеличению конверсий.`,
        },
        {
          title: "Технический аудит",
          text: `Проверяем сайт или систему на наличие технических ошибок: производительность,
           безопасность, адаптивность. Анализируем скорость загрузки страниц, корректность
           работы функционала и соответствие современным стандартам. Предлагаем
           рекомендации для оптимизации.`,
        },
        {
          title: "Исследование конкурентов",
          text: `Анализируем сайты и стратегии конкурентов, выявляем их сильные и слабые стороны.
           Помогаем определить, как ваш продукт может выделиться на фоне рынка,
           и предлагаем уникальные подходы к позиционированию.`,
        },
        {
          title: "Бизнес-аналитика",
          text: `Изучаем внутренние процессы компании, проводим оценку эффективности и
           рентабельности. Предлагаем решения для оптимизации, включая автоматизацию
           процессов и внедрение цифровых инструментов, чтобы повысить производительность
           бизнеса.`,
        },
        {
          title: "Анализ пользовательского пути (CJM)",
          text: `Создаём карту путешествия клиента (CJM), чтобы понять, как пользователи
           взаимодействуют с вашим продуктом. Выявляем ключевые точки контакта,
           где возможно улучшение, и предлагаем способы повысить лояльность
           аудитории.`,
        },
        {
          title: "Анализ данных и построение отчетности",
          text: `Работаем с большими данными: собираем, систематизируем и анализируем
           информацию о вашем проекте. Создаём удобные отчёты и дашборды, чтобы вы
           всегда имели доступ к ключевым метрикам эффективности.`,
        },
        {
          title: "Анализ эффективности digital-стратегий",
          text: `Проводим аудит ваших текущих digital-кампаний, включая SEO, контекстную
           рекламу и SMM. Оцениваем их результативность и предлагаем шаги
           для улучшения. Помогаем выстроить стратегию, которая принесёт
           максимум отдачи.`,
        },
        {
          title: "Системный аудит и оценка архитектуры",
          text: `Анализируем архитектуру вашего IT-продукта: от структуры базы данных
           до функциональных связей. Предоставляем отчёт с рекомендациями по
           улучшению масштабируемости, безопасности и интеграции систем.`,
        },
      ],
    };
  },
  methods: {
    toggleModal(index) {
      const box = this.$refs[`box_solutions_${index}`][0];
      const text = this.$refs[`block_max_text_${index}`][0];
      if (box && text) {
        box.classList.toggle("active");
        text.classList.toggle("active");
      }
    },
  },
  setup() {
    useHead({
      title:
        "Комплексная аналитика и аудит: UX, бизнес-аналитика, конкурентный анализ",
      meta: [
        {
          name: "description",
          content:
            "Проводим комплексную аналитику: UX-исследования, технический аудит, бизнес-аналитика, анализ конкурентной среды. Выявляем слабые места, разрабатываем стратегии роста и увеличиваем эффективность вашего проекта.",
        },
      ],
    });
  },
};
</script>
<style scoped>
.container_text_full_pages {
  padding-left: 6rem;
}
.section_advantages_services {
  padding: 8rem 1rem 0rem 1rem;
}
.text_position_services {
  bottom: -1rem;
}
.text_position_advantages_services {
  top: 1.5rem;
}
.text_position_advantages_services_v2 {
  bottom: 1rem;
}
@media (max-width: 768px) {
  .text_position_services {
    bottom: -8rem;
  }
  .section_advantages_services {
    padding: 10rem 1rem 0rem 1rem;
  }
  .container_text_full_pages {
    padding: 12rem 2.4rem 0rem 2.4rem;
  }
  .title_home_1 {
    margin: 0px;
  }
  .box_button_brifing_full_pages {
    margin-top: 2rem;
  }
}
</style>
