<template>
  <section ref="section_briefing_bid" class="section_briefing_bid">
    <div class="container_briefing_modal">
      <div class="box_logo_briefing_modal">
        <button @click="closeBig">
          <img class="logo_header" src="@/assets/logo/logo.svg" />
        </button>
      </div>
      <img
        @click="closeBig"
        src="@/assets/elements/arrow_back_bid.svg"
        class="img_arrow_back_bid"
      />
      <div class="box_title_home_briefing_modal">
        <p class="title_bid text_align_right">
          ОБСУДИТЬ<br />
          <span class="color_violet">ПРОЕКТ</span>
        </p>
      </div>
      <div class="box_contacts_briefing">
        <a href="mailto:info@mti-dev.ru" class="medium_text">info@mti-dev.ru</a>
        <a href="tel:+79236922250" class="medium_text">+7 923 692 2250</a>
      </div>
      <img
        ref="section_briefing_wave"
        src="@/assets/background/section_briefing.png"
        class="section_briefing_wave"
      />
      <div
        ref="container_briefing_wave_blue_mobile"
        class="container_briefing_wave_blue_mobile"
      >
        <div class="box_briefing_wave_blue_mobile">
          <img
            ref="briefing_wave_blue_mobile"
            src="@/assets/background/briefing_wave_blue_mobile.svg"
            class="briefing_wave_blue_mobile"
          />
        </div>
      </div>
      <img
        ref="section_briefing_wave_blue"
        src="@/assets/background/section_briefing_wave_blue.png"
        class="section_briefing_wave_blue"
      />
      <div ref="container_bid" class="container_bid">
        <div
          ref="box_bid"
          class="box_bid"
          :class="{ sent: isSent, active: isActive }"
        >
          <div @click="closeBig" class="box_cross" :class="{ sent: isSent }">
            <img src="@/assets/elements/cross.svg" class="cross_bid" />
          </div>
          <div class="box_button_eng_header poz_box_button_eng_header">
            <div class="button_eng_header">eng</div>
          </div>
          <div class="box_input_bid" :class="{ sent: isSent }">
            <div class="box_input_error_bid">
              <input
                type="text"
                placeholder="Имя"
                class="input_bid"
                :class="{ error_input: errors.name }"
                v-model="formData.name"
                @input="onInput('name')"
                @blur="validateName"
                @keypress="filterName"
              />
              <div class="height_1rem">
                <span v-if="errors.name" class="text_error">{{
                  errors.name
                }}</span>
              </div>
            </div>

            <div class="box_input_error_bid">
              <input
                type="tel"
                placeholder="+7(999)-999-99-99"
                class="input_bid"
                :class="{ error_input: errors.phone }"
                v-model="formData.phone"
                @keypress="filterPhone"
                @input="formatPhone"
                @blur="validatePhone"
              />
              <div class="height_1rem">
                <span v-if="errors.phone" class="text_error">{{
                  errors.phone
                }}</span>
              </div>
            </div>

            <div class="box_input_error_bid">
              <input
                type="email"
                placeholder="Email"
                class="input_bid"
                :class="{ error_input: errors.email }"
                v-model="formData.email"
                @input="onInput('email')"
                @blur="validateEmail"
              />
              <div class="height_1rem">
                <span v-if="errors.email" class="text_error">{{
                  errors.email
                }}</span>
              </div>
            </div>

            <div class="box_input_error_bid">
              <input
                type="text"
                placeholder="Компания"
                class="input_bid"
                v-model="formData.company"
              />
            </div>
            <input
              type="text"
              placeholder="Коментарий"
              class="input_bid"
              v-model="formData.comment"
            />
          </div>
          <div class="box_found_out_bid" :class="{ sent: isSent }">
            <p class="text_small_kreadon-bold color_violet">
              Откуда узнали про MTI-DEV?
            </p>
            <div class="box_button_bid">
              <button
                v-for="option in sources"
                :key="option"
                class="button_blog"
                :class="{ active: activeSources.includes(option) }"
                @click="toggleSource(option)"
              >
                {{ option }}
              </button>
            </div>
          </div>
          <div class="box_confirm_send_bid" :class="{ sent: isSent }">
            <div class="box_confirm_bid">
              <input
                type="checkbox"
                class="input_checkbox"
                :class="{
                  active: isCheckboxActive,
                  error_checkbox: !isCheckboxActive && triedToSubmit,
                }"
                @change="toggleCheckbox"
              />
              <p class="subtext_services_design color_violet">
                Нажимая на кнопку, вы подтверждаете согласие на
                <a
                  href="https://mti-dev.ru/privacy_policy.pdf"
                  target="_blank"
                  class="underline color_violet"
                  >обработку персональных данных</a
                >
              </p>
            </div>
            <div class="box_button_v1">
              <button class="button_v1" @click="submitForm">Отправить</button>
            </div>
          </div>
          <div class="box_contacts_briefing_mobil">
            <a href="mailto:info@mti-dev.ru" class="medium_text"
              >info@mti-dev.ru</a
            >
            <a href="tel:+79236922250" class="medium_text">+7 923 692 2250</a>
          </div>
          <div class="box_bid_sent" :class="{ sent: isSent }">
            <div></div>
            <div class="box_sent_text_bid">
              <p class="subtext_services_design color_violet text_align_center">
                Мы получили вашу заявку.<br />
                В ближайшее время мы свяжемся с вами.
              </p>
              <div class="box_button_bid_sent">
                <button class="button_v1" @click="resetForm">
                  Отправить повторно
                </button>
              </div>
            </div>
            <div class="box_text_num_bid">
              <p class="subtext_services_design">Условия обработки данных</p>
              <div class="box_contacts_briefing_mobil">
                <a href="mailto:info@mti-dev.ru" class="medium_text"
                  >info@mti-dev.ru</a
                >
                <a href="tel:+79236922250" class="medium_text"
                  >+7 923 692 2250</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      formData: {
        name: "",
        phone: "",
        email: "",
        company: "",
        comment: "",
      },
      errors: {
        name: null,
        phone: null,
        email: null,
      },
      touched: {
        name: false,
        phone: false,
        email: false,
      },
      sources: [
        "Поиск",
        "Рекомендация",
        "Рейтинги",
        "Соцсети",
        "Блог",
        "Другое",
      ],
      activeSources: [],
      isCheckboxActive: false,
      triedToSubmit: false, // Флаг для отображения ошибки у чекбокса
      isSent: false,
      isActive: false,
    };
  },
  methods: {
    resetForm() {
      this.isSent = false; // Сбрасываем состояние отправки
      this.formData = {
        name: "",
        phone: "",
        email: "",
        company: "",
        comment: "",
      }; // Очищаем данные формы
      this.errors = {
        name: null,
        phone: null,
        email: null,
      }; // Сбрасываем ошибки
      this.touched = {
        name: false,
        phone: false,
        email: false,
      }; // Сбрасываем статус касания полей
      this.isCheckboxActive = false; // Сбрасываем чекбокс
      this.triedToSubmit = false; // Сбрасываем флаг отправки
    },
    onInput(field) {
      this.touched[field] = true; // Помечаем поле как "использованное"
      this.errors[field] = null; // Помечаем поле как "было использовано"
    },
    filterName(event) {
      const char = String.fromCharCode(event.keyCode || event.which);
      const allowedRegex = /^[a-zA-Zа-яА-Я\s]*$/;
      if (!char.match(allowedRegex)) {
        event.preventDefault(); // Запрещаем ввод символа
      }
    },
    validateName() {
      const nameRegex = /^[a-zA-Zа-яА-Я\s]*$/;
      if (!this.formData.name) {
        this.errors.name = "Обязательное поле";
      } else if (this.formData.name.length < 2) {
        this.errors.name = "Минимум 2 символа.";
      } else if (!this.formData.name.match(nameRegex)) {
        this.errors.name = "Имя может содержать только буквы.";
      } else {
        this.errors.name = null;
      }
    },
    filterPhone(event) {
      const char = String.fromCharCode(event.keyCode || event.which);
      const allowedRegex = /^[\d+]*$/; // Разрешаем только цифры и знак "+"

      // Блокируем ввод, если длина уже достигла 11 цифр (без учёта символов форматирования)
      const digitsCount = this.formData.phone.replace(/[^0-9]/g, "").length;
      if (!char.match(allowedRegex) || digitsCount >= 11) {
        event.preventDefault(); // Запрещаем ввод
      }
    },
    formatPhone() {
      // Удаляем все символы, кроме цифр и "+"
      let cleaned = this.formData.phone.replace(/[^0-9+]/g, "");

      // Ограничиваем количество цифр до 11
      if (cleaned.startsWith("+7")) {
        cleaned = "+7" + cleaned.slice(2, 12); // Для номера, начинающегося с +7
      } else if (cleaned.startsWith("8")) {
        cleaned = "8" + cleaned.slice(1, 11); // Для номера, начинающегося с 8
      } else if (cleaned.startsWith("7")) {
        cleaned = "+7" + cleaned.slice(1, 11); // Для номера, начинающегося с 7
      }

      // Применяем поэтапное форматирование
      if (cleaned.startsWith("+7")) {
        cleaned = cleaned
          .replace(/^(\+7)(\d{0,3})/, "$1($2") // +7(XXX
          .replace(/(\(\d{3})/, (match, p1) => {
            return cleaned.length > 4 ? `${p1}) ` : p1; // Добавляем `)` и пробел одновременно, если есть цифры за скобкой
          })
          .replace(/(\)\s\d{0,3})/, "$1") // +7(XXX) YYY
          .replace(/(\s\d{3})(\d{0,2})/, "$1-$2") // +7(XXX) YYY-ZZ
          .replace(/(-\d{2})(\d{0,2})/, "$1-$2"); // +7(XXX) YYY-ZZ-WW
      } else if (cleaned.startsWith("8")) {
        cleaned = cleaned
          .replace(/^(8)(\d{0,3})/, "$1($2") // 8(XXX
          .replace(/(\(\d{3})/, (match, p1) => {
            return cleaned.length > 4 ? `${p1}) ` : p1; // Добавляем `)` и пробел одновременно, если есть цифры за скобкой
          })
          .replace(/(\)\s\d{0,3})/, "$1") // 8(XXX) YYY
          .replace(/(\s\d{3})(\d{0,2})/, "$1-$2") // 8(XXX) YYY-ZZ
          .replace(/(-\d{2})(\d{0,2})/, "$1-$2"); // 8(XXX) YYY-ZZ-WW
      }

      // Удаляем закрывающую скобку и пробел, если цифры за скобкой отсутствуют
      cleaned = cleaned.replace(/\(\d{3}\) $/, "($1"); // Убираем `)` и пробел, если цифр за ними нет

      // Удаляем лишние символы в конце, если не хватает цифр
      cleaned = cleaned.replace(/[-\s]$/, ""); // Удаляем лишние символы (-, пробел) в конце

      this.formData.phone = cleaned;
    },
    validatePhone() {
      const phoneRegex = /^(\+7|8)\(\d{3}\) \d{3}-\d{2}-\d{2}$/; // Проверяем правильный формат
      if (!this.formData.phone) {
        this.errors.phone = "Обязательное поле";
      } else if (!this.formData.phone.match(phoneRegex)) {
        this.errors.phone = "Введите корректный номер";
      } else {
        this.errors.phone = null;
      }
    },
    validateEmail() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.formData.email) {
        this.errors.email = "Обязательное поле";
      } else if (!this.formData.email.match(emailRegex)) {
        this.errors.email = "Введите корректный email.";
      } else {
        this.errors.email = null;
      }
    },
    validateForm() {
      this.validateName();
      this.validatePhone();
      this.validateEmail();

      if (!this.errors.name && !this.errors.phone && !this.errors.email) {
        alert("Форма успешно отправлена!");
        // Логика для отправки данных
      } else {
        alert("Пожалуйста, исправьте ошибки в форме.");
      }
    },
    toggleSource(option) {
      if (this.activeSources.includes(option)) {
        this.activeSources = this.activeSources.filter((src) => src !== option);
      } else {
        this.activeSources.push(option);
      }
    },
    toggleCheckbox() {
      this.isCheckboxActive = !this.isCheckboxActive;
    },
    toggleBig() {
      this.isActive = !this.isActive;

      this.$refs.container_briefing_wave_blue_mobile.classList.toggle("active");
      this.$refs.container_bid.classList.toggle("active");
      this.$refs.section_briefing_wave_blue.classList.toggle("active");
      this.$refs.section_briefing_wave.classList.toggle("active");
      this.$refs.section_briefing_bid.classList.toggle("active");
      document.body.classList.add("no-scroll");
    },
    closeBig() {
      this.isActive = false;
      this.$refs.container_briefing_wave_blue_mobile.classList.remove("active");
      this.$refs.box_bid.classList.remove("active");
      this.$refs.container_bid.classList.remove("active");
      this.$refs.section_briefing_wave_blue.classList.remove("active");
      this.$refs.section_briefing_wave.classList.remove("active");
      this.$refs.section_briefing_bid.classList.remove("active");
      document.body.classList.remove("no-scroll");
    },
    handleClick(event) {
      const action = event.target.dataset.action;

      if (action === "toggleBig") {
        this.toggleBig();
      }
    },
    submitForm() {
      this.triedToSubmit = true;
      this.validateName();
      this.validatePhone();
      this.validateEmail();

      if (
        !this.errors.name &&
        !this.errors.phone &&
        !this.errors.email &&
        this.isCheckboxActive
      ) {
        console.log({
          name: this.formData.name,
          phone: this.formData.phone,
          email: this.formData.email,
          company: this.formData.company,
          comment: this.formData.comment,
          sources: this.activeSources,
        });
        this.sendModalOrder();

        this.triedToSubmit = false;
        this.isSent = true;
        // alert("Форма успешно отправлена!");
      }
      //   else {
      //     alert("Пожалуйста, заполните все поля и подтвердите согласие.");
      //   }
    },
    sendModalOrder() {
      return fetch("https://mti-dev.ru:49152/modalFeedBack", {
        method: "POST",
        headers: { "Content-Type": "application/json;charset=utf-8" },
        body: JSON.stringify({
          name: this.formData.name,
          phone: this.formData.phone,
          email: this.formData.email,
          company: this.formData.company,
          comment: this.formData.comment,
          sources: this.activeSources,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          return response.data;
        });
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClick);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClick);
  },
};
</script>
<style>
.section_briefing_bid {
  position: fixed;
  width: 100%;
  right: -115%;
  height: 100vh;
  z-index: 4;
  background: rgb(255, 255, 255);
  transition: 1s ease;
}
.section_briefing_bid.active {
  right: 0%;
  transition: 1.5s ease;
}
.container_briefing_modal {
  position: relative;
  width: 100%;
  height: 100%;
}
.box_title_home_briefing_modal {
  position: absolute;
  left: 6rem;
  top: 12.52rem;
}
.box_sent_text_bid {
  display: flex;
  flex-direction: column;
  gap: 2.25rem;
  align-items: center;
}
.box_contacts_briefing {
  display: flex;
  gap: 3.5rem;
  position: absolute;
  bottom: 4rem;
  left: 6rem;
}
.section_briefing_wave_blue {
  width: 46.05rem;
  height: 100%;
  position: absolute;
  right: -46rem;
  transition: 1s ease;
}
.section_briefing_wave_blue.active {
  right: 0rem;
  transition: 1s ease;
}

.section_briefing_wave {
  width: 122.83rem;
  height: 69.6rem;
  transform: rotate(-5deg);
  position: absolute;
  left: -10rem;
  top: -20rem;
  transition: 1s ease;
  z-index: -1;
}
.box_button_bid_sent {
  width: 16.05rem;
  height: 3.4rem;
}
.section_briefing_wave.active {
  width: 122.83rem;
  height: 69.6rem;
  transform: rotate(15deg);
  position: absolute;
  left: -10rem;
  top: -5rem;
  transition: 1s ease;
}
.box_logo_briefing_modal {
  position: absolute;
  top: 2.3rem;
  left: 4rem;
}
.container_bid {
  position: absolute;
  width: 31.85rem;
  height: calc(100% - 1rem);
  bottom: -100%;
  right: 3.5rem;
  border-radius: 2.1rem 2.1rem 0rem 0rem;
  box-shadow: 0.7rem 0.7rem 2rem -0.8rem rgba(71, 86, 163, 0.4);
  background: rgb(255, 255, 255);
  transition: 1s ease;
  overflow: hidden;
}
.container_bid.active {
  bottom: 0%;
  transition: 1.5s cubic-bezier(0.48, -1.09, 0.11, 0.29);
}
.box_bid_sent {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  transition: 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 8rem 0rem 4rem 0rem;
}
.box_bid_sent.sent {
  opacity: 1;
  z-index: 2;
  transition: 0.5s ease;
}
.box_bid {
  min-width: 100%;
  height: 100%;
  position: relative;
  padding: 20rem 3rem 4rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  overflow: scroll;
  transition: 2s cubic-bezier(0.72, -0.72, 0.27, 1.55);
}
/* .box_bid.sent {
  overflow: hidden;
} */
.box_bid.active {
  padding: 4rem 3rem 4rem 3rem;
  transition: 2s cubic-bezier(0.72, -0.72, 0.27, 1.55);
}

.poz_box_button_eng_header {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
.box_input_bid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  gap: 1.1rem;
  opacity: 1;
  transition: 0.5s;
}
.box_input_bid.sent {
  opacity: 0;
  transition: 0.5s;
}
.input_bid {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1rem;
  color: rgb(91, 108, 255);
  font-family: Kreadon-Regular;
  width: 100%;
  padding-bottom: 0.75rem;
  border-bottom: 0.1rem solid rgb(91, 108, 255);
}
.input_bid::placeholder {
  color: rgb(91, 108, 255); /* Цвет плейсхолдера */
}
.box_found_out_bid {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  opacity: 1;
  transition: 0.5s ease;
}
.box_found_out_bid.sent {
  opacity: 0;
  transition: 0.5s ease;
}
.box_button_bid {
  display: flex;
  gap: 0.7rem;
  row-gap: 0.6rem;
  flex-wrap: wrap;
}
.box_button_bid .button_blog {
  padding: 0.5rem 1.5rem;
}
.input_checkbox {
  min-width: 2rem;
  height: 2rem;
  box-sizing: border-box;
  border: 0.15rem solid rgb(91, 108, 255);
  border-radius: 1.7rem;
  background: rgb(255, 255, 255);
  transition: 0.5s ease;
}

.input_checkbox.active {
  background: rgb(91, 108, 255);
  transition: 0.5s ease;
  background-image: url("@/assets/elements/check_mark.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  background-size: 1rem 1rem;
}

.box_confirm_bid {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}
.box_confirm_send_bid {
  display: flex;
  flex-direction: column;
  gap: 1.7rem;
  align-items: center;
  opacity: 1;
  transition: 0.5s ease;
}
.box_confirm_send_bid.sent {
  opacity: 0;
  transition: 0.5s ease;
}
.text_error {
  color: rgb(238, 90, 137);
  font-family: Kreadon-Regular;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.05rem;
}
.box_input_error_bid {
  display: flex;
  flex-direction: column;
  width: 44%;
}
.height_1rem {
  height: 1rem;
}
.error_input {
  border-bottom: 0.1rem solid #ce2f57;
  color: #ce2f57;
}

.error_input::placeholder {
  color: #ce2f57;
}

.error_checkbox {
  border-color: #ce2f57;
}
.box_cross {
  display: none;
  position: absolute;
  top: 1.7rem;
  left: 1.7rem;
  cursor: pointer;
  z-index: 3;
}
.box_cross.sent {
  display: block;
}
.title_bid {
  font-family: Kreadon-Bold;
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 142.1%;
}
.box_contacts_briefing_mobil {
  display: none;
}
.box_bid_sent .box_contacts_briefing_mobil {
  display: none;
}
.container_briefing_wave_blue_mobile {
  display: none;
}
.img_arrow_back_bid {
  display: none;
}
@media (max-width: 767px) {
  .section_briefing_wave {
    top: -10rem;
    width: auto;
    height: 120%;
  }
  .img_arrow_back_bid {
    display: block;
    width: 1.77rem;
    height: 1.55rem;
    position: absolute;
    left: 2rem;
    top: 10rem;
  }
  .section_briefing_wave.active {
    height: 120%;
    top: 5rem;
    left: -20rem;
  }
  .section_briefing_wave_blue {
    display: none;
  }
  .container_bid {
    width: 32.27rem;
    height: 62%;
    right: 1.2rem;
    box-shadow: 1.27rem 1.27rem 3.64rem -1.45rem rgba(71, 86, 163, 0.4);
  }
  .box_input_error_bid {
    width: 100%;
  }
  .box_bid.active {
    padding: 2.5rem 3rem 4rem 3rem;
    gap: 3rem;
  }
  .input_bid {
    font-size: 1.27rem;
    line-height: 1.82rem;
  }
  .cross_bid {
    width: 1.64rem;
    height: 1.64rem;
  }
  .box_cross {
    display: none;
    position: absolute;
    top: 3rem;
    left: 3rem;
    cursor: pointer;
  }
  .section_briefing_bid {
    z-index: 3;
    right: -150%;
  }
  .box_title_home_briefing_modal {
    right: 2.4rem;
    top: 8rem;
  }
  .title_bid {
    font-family: Kreadon-Bold;
    font-size: 3.27rem;
    font-weight: 700;
    line-height: 142.1%;
  }
  .box_contacts_briefing {
    display: none;
  }
  .box_contacts_briefing_mobil {
    display: flex;
    justify-content: space-between;
  }
  .box_bid_sent .box_contacts_briefing_mobil {
    width: 80%;
    display: flex;
  }
  .box_bid_sent {
    padding: 2.3rem 0rem 2.3rem 0rem;
  }
  .box_text_num_bid {
    display: flex;
    gap: 5.5rem;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  .box_button_bid_sent {
    width: 19.73rem;
    height: 4.55rem;
  }
  .container_briefing_wave_blue_mobile {
    display: block;
    background: linear-gradient(
      -58.27deg,
      rgb(219, 242, 255) -8.497%,
      rgb(255, 235, 254) 117.084%
    );
    position: absolute;
    bottom: -100%;
    width: 150%;
    height: 73%;
    right: -20rem;
    transition: 1.5s ease;
    border-top-left-radius: 35rem;
    overflow: hidden;
  }
  .container_briefing_wave_blue_mobile.active {
    bottom: 0%;
    right: 0rem;
    transition: 1.5s cubic-bezier(0.48, -1.09, 0.11, 0.29);
  }
  .box_briefing_wave_blue_mobile {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .briefing_wave_blue_mobile {
    position: absolute;
    width: 100%;
    height: auto;
    right: 0px;
    top: 0px;
    object-position: top right;
  }
}
</style>
