<template>
  <HeaderPages />
  <section class="section_header_full_pages">
    <div class="waves_full_pages"></div>
    <div class="container_text_full_pages">
      <h1 class="title_home">
        <span class="title_home_4 color_violet">UI/UX Дизайн и<br /></span>
        <span class="title_home_1">Фирменный стиль<br /></span>
      </h1>
      <div class="box_button_brifing_full_pages">
        <div class="box_button_v2">
          <button class="button_v1">Оставить заявку</button>
        </div>
      </div>
      <p class="medium_text text_position_services">
        Создаём запоминающийся дизайн и фирменный стиль, которые подчёркивают
        уникальность вашего бренда и привлекают клиентов.
      </p>
    </div>
  </section>
  <section class="section_advantages_services">
    <div class="container_advantages">
      <h2 class="medium_text text_position_advantages_services">
        Глубокое<br />
        понимание бренда
      </h2>
      <img src="@/assets/background/analytics.png" class="img_advantages" />
      <p
        class="font_1_400_Kreadon_Regular text_position_advantages_services_v2"
      >
        Исследуем рынок, анализируем конкурентов, изучаем аудиторию и выявляем
        точки роста.
      </p>
    </div>
    <div class="container_advantages">
      <h2 class="medium_text text_position_advantages_services">
        Пользовательский опыт
      </h2>
      <img src="@/assets/background/flexible.png" class="img_advantages" />
      <p
        class="font_1_400_Kreadon_Regular text_position_advantages_services_v2"
      >
        Прозрачные и гибкие подходы гарантируют адаптацию к изменяющимся
        требованиям.
      </p>
    </div>
    <div class="container_advantages">
      <h2 class="medium_text text_position_advantages_services">
        Современные инструменты
      </h2>
      <img src="@/assets/background/control.png" class="img_advantages" />
      <p
        class="font_1_400_Kreadon_Regular text_position_advantages_services_v2"
      >
        Создаём интерфейсы, которые удерживают внимание и конвертируют
        пользователей.
      </p>
    </div>
    <div class="container_advantages">
      <h2 class="medium_text text_position_advantages_services">
        Увеличение конверсии
      </h2>
      <img src="@/assets/background/reduction.png" class="img_advantages" />
      <p
        class="font_1_400_Kreadon_Regular text_position_advantages_services_v2"
      >
        Минимизируем время от идеи до запуска, обеспечивая высокое качество.
      </p>
    </div>
  </section>
  <section class="section_solutions">
    <h2 class="title_services_design color_violet">РЕШЕНИЯ</h2>
    <div class="container_solutions">
      <div
        v-for="(solution, index) in solutions"
        :key="index"
        @click="solution.text && toggleModal(index)"
        :ref="'box_solutions_' + index"
        class="box_solutions"
      >
        <div class="block_solutions">
          <p class="font_1_6_400_Kreadon_Regular">{{ solution.title }}</p>
          <!-- Стрелка отображается только если есть текст -->
          <img
            v-if="solution.text"
            src="@/assets/elements/arrow_href.svg"
            class="arrow_href_img"
          />
        </div>
        <!-- Текстовый блок, который может быть скрыт, если текст отсутствует -->
        <div :ref="'block_max_text_' + index" class="block_max_text">
          <p
            v-if="solution.text"
            class="font_1_400_Kreadon_Regular color_violet"
          >
            {{ solution.text }}
          </p>
        </div>
      </div>
    </div>
  </section>
  <SectionProjectsMain />
  <FooterPages />
</template>
<script>
import HeaderPages from "../permanent/HeaderPages.vue";
import FooterPages from "../permanent/FooterPages.vue";
import SectionProjectsMain from "../ui/SectionProjectsMain.vue";
import { useHead } from "@vueuse/head";

export default {
  components: { HeaderPages, SectionProjectsMain, FooterPages },
  data() {
    return {
      solutions: [
        {
          title: "UX/UI дизайн",
          text: `Разработка уникальных интерфейсов, которые сочетают эстетику и
           удобство. Мы создаём макеты и прототипы, ориентированные на
           потребности вашего бизнеса и пользователей.`,
        },
        {
          title: "Фирменный стиль",
          text: `Создание запоминающейся идентичности бренда: от логотипов до
           брендбуков. Мы помогаем компаниям выделяться и формировать
           доверие у аудитории.`,
        },
        {
          title: "Дизайн-системы",
          text: `Разрабатываем структурированные дизайн-системы, которые упрощают
           поддержку и масштабирование вашего продукта, сохраняя единство
           стиля и функциональности.`,
        },
        {
          title: "Редизайн сайтов",
          text: `Обновляем существующие сайты, улучшая их визуальную составляющую
           и удобство использования. Обеспечиваем современный вид и
           соответствие требованиям рынка.`,
        },
        {
          title: "Дизайн мобильных приложений",
          text: `Создаём мобильные интерфейсы для iOS и Android, которые
           обеспечивают удобное взаимодействие и повышают лояльность
           пользователей.`,
        },
        {
          title: "3D-визуализация",
          text: `Используем 3D-графику для создания сложных визуализаций:
           от рекламных материалов до презентаций продуктов.`,
        },
      ],
    };
  },
  methods: {
    toggleModal(index) {
      const box = this.$refs[`box_solutions_${index}`][0];
      const text = this.$refs[`block_max_text_${index}`][0];
      if (box && text) {
        box.classList.toggle("active");
        text.classList.toggle("active");
      }
    },
  },
  setup() {
    useHead({
      title: "UI/UX дизайн и фирменный стиль: логотипы, брендинг, редизайн",
      meta: [
        {
          name: "description",
          content:
            "Создание уникальных дизайнов: UX/UI, фирменный стиль, логотипы, дизайн-системы и редизайн сайтов. Современные решения, которые выделяют ваш бренд и повышают его эффективность.",
        },
      ],
    });
  },
};
</script>
<style scoped>
.container_text_full_pages {
  padding-left: 6rem;
}
.section_advantages_services {
  padding: 8rem 1rem 0rem 1rem;
}
.text_position_services {
  bottom: -1rem;
}
.text_position_advantages_services {
  top: 1.5rem;
}
@media (max-width: 768px) {
  .text_position_services {
    bottom: -8rem;
  }
  .section_advantages_services {
    padding: 10rem 1rem 0rem 1rem;
  }
  .container_text_full_pages {
    padding: 12rem 2.4rem 0rem 2.4rem;
  }
  .title_home_1 {
    margin: 0px;
  }
  .box_button_brifing_full_pages {
    margin-top: 2rem;
  }
}
</style>
