<template>
  <HeaderPages />
  <section class="section_header_full_pages">
    <div class="waves_full_pages"></div>
    <div class="container_text_full_pages">
      <h1 class="title_home">
        <span class="title_home_4">Техническая поддержка и<br /></span>
        <span class="title_home_4 color_violet">обслуживание<br /></span>
      </h1>
      <div class="box_button_brifing_full_pages">
        <div class="box_button_v2">
          <button class="button_v1">Оставить заявку</button>
        </div>
      </div>
      <p class="medium_text text_position_services">
        Обеспечиваем бесперебойную работу сайтов, оперативное решение проблем и
        обновление функционала для эффективного бизнеса.
      </p>
    </div>
  </section>

  <section class="section_advantages_services">
    <div class="container_advantages">
      <h2 class="medium_text text_position_advantages_services">
        Бесперебойная работа
      </h2>
      <img src="@/assets/background/analytics.png" class="img_advantages" />
      <p
        class="font_1_400_Kreadon_Regular text_position_advantages_services_v2"
      >
        Поддерживаем стабильность вашего сайта, предотвращаем сбои и быстро
        устраняем неисправности.
      </p>
    </div>
    <div class="container_advantages">
      <h2 class="medium_text text_position_advantages_services">
        Защита и безопасность
      </h2>
      <img src="@/assets/background/flexible.png" class="img_advantages" />
      <p
        class="font_1_400_Kreadon_Regular text_position_advantages_services_v2"
      >
        Обеспечиваем защиту сайта от вирусов, хакерских атак и угроз
        безопасности.
      </p>
    </div>
    <div class="container_advantages">
      <h2 class="medium_text text_position_advantages_services">
        Модернизация и<br />
        обновления
      </h2>
      <img src="@/assets/background/control.png" class="img_advantages" />
      <p
        class="font_1_400_Kreadon_Regular text_position_advantages_services_v2"
      >
        Регулярно обновляем функционал, контент и технологии вашего сайта.
      </p>
    </div>
    <div class="container_advantages">
      <h2 class="medium_text text_position_advantages_services">
        Персонализированный<br />
        подход
      </h2>
      <img src="@/assets/background/reduction.png" class="img_advantages" />
      <p
        class="font_1_400_Kreadon_Regular text_position_advantages_services_v2"
      >
        Подстраиваем наши услуги под потребности вашего бизнеса, обеспечивая
        максимальную отдачу.
      </p>
    </div>
  </section>

  <section class="section_solutions">
    <h2 class="title_services_design color_violet">РЕШЕНИЯ</h2>
    <div class="container_solutions">
      <div
        v-for="(solution, index) in solutions"
        :key="index"
        @click="solution.text && toggleModal(index)"
        :ref="'box_solutions_' + index"
        class="box_solutions"
      >
        <div class="block_solutions">
          <p class="font_1_6_400_Kreadon_Regular">{{ solution.title }}</p>
          <img
            v-if="solution.text"
            src="@/assets/elements/arrow_href.svg"
            class="arrow_href_img"
          />
        </div>
        <div :ref="'block_max_text_' + index" class="block_max_text">
          <p
            v-if="solution.text"
            class="font_1_400_Kreadon_Regular color_violet"
          >
            {{ solution.text }}
          </p>
        </div>
      </div>
    </div>
  </section>
  <SectionProjectsMain />
  <FooterPages />
</template>

<script>
import HeaderPages from "../permanent/HeaderPages.vue";
import FooterPages from "../permanent/FooterPages.vue";
import SectionProjectsMain from "../ui/SectionProjectsMain.vue";
import { useHead } from "@vueuse/head";

export default {
  components: { HeaderPages, SectionProjectsMain, FooterPages },
  data() {
    return {
      solutions: [
        {
          title: "Базовая техническая поддержка",
          text: `Регулярное техническое обслуживание вашего сайта, включая контроль стабильности работы, устранение мелких неисправностей и обновление системы безопасности. Это позволяет избежать простоев и поддерживать актуальность сайта.`,
        },
        {
          title: "Обслуживание и доработка сайта",
          text: `Если вашему сайту требуется модернизация, мы предлагаем расширение функционала, обновление дизайна и интеграцию новых возможностей. Это поможет вашему бизнесу соответствовать современным требованиям.`,
        },
        {
          title: "Обновление контента",
          text: `Регулярное обновление контента помогает вашему сайту оставаться актуальным и интересным для аудитории. Мы создаём новые материалы, обновляем изображения и тексты, а также адаптируем контент под SEO.`,
        },
        {
          title: "Оптимизация скорости загрузки",
          text: `Улучшение скорости загрузки страниц за счёт оптимизации кода, сжатия изображений и настройки сервера. Это позволяет повысить удобство использования сайта и улучшить его позиции в поисковых системах.`,
        },
        {
          title: "Настройка и поддержка хостинга",
          text: `Мы настраиваем сервер для стабильной работы вашего сайта, обеспечиваем его безопасность и проводим регулярное обслуживание. Это гарантирует высокую производительность и минимальное время простоя.`,
        },
        {
          title: "Мониторинг производительности",
          text: `Постоянный мониторинг ключевых параметров сайта, таких как скорость загрузки, ошибки и безопасность. Мы своевременно выявляем проблемы и внедряем решения для их устранения.`,
        },
        {
          title: "Защита от угроз",
          text: `Обеспечиваем защиту сайта от вирусов, хакерских атак и утечек данных. Устанавливаем SSL-сертификаты, настраиваем защиту от DDoS-атак и проводим регулярные проверки безопасности.`,
        },
      ],
    };
  },
  methods: {
    toggleModal(index) {
      const box = this.$refs[`box_solutions_${index}`][0];
      const text = this.$refs[`block_max_text_${index}`][0];
      if (box && text) {
        box.classList.toggle("active");
        text.classList.toggle("active");
      }
    },
  },
  setup() {
    useHead({
      title:
        "Техническая поддержка сайтов: Обновление контента, оптимизация, защита и мониторинг",
      meta: [
        {
          name: "description",
          content:
            "Обеспечиваем техническую поддержку и обслуживание сайтов. Регулярные обновления, оптимизация скорости, защита от атак и мониторинг. Комплексный подход для бесперебойной работы.",
        },
      ],
    });
  },
};
</script>

<style scoped>
.container_text_full_pages {
  padding-left: 6rem;
}
.section_advantages_services {
  padding: 8rem 1rem 0rem 1rem;
}
.text_position_services {
  bottom: -1rem;
}
.text_position_advantages_services {
  top: 1.5rem;
}
.text_position_advantages_services_v2 {
  bottom: 1rem;
}
@media (max-width: 768px) {
  .text_position_services {
    bottom: -8rem;
  }
  .section_advantages_services {
    padding: 10rem 1rem 0rem 1rem;
  }
  .container_text_full_pages {
    padding: 12rem 2.4rem 0rem 2.4rem;
  }
  .title_home_1 {
    margin: 0px;
  }
  .box_button_brifing_full_pages {
    margin-top: 2rem;
  }
}
</style>
