<template>
  <HeaderPages />
  <section class="section_header_full_pages">
    <div class="waves_full_pages"></div>
    <div class="container_text_full_pages">
      <h1 class="title_home">
        <span class="title_home_4">SEO оптимизация и <br /></span>
        <span class="title_home_4 color_violet">продвижение<br /></span>
      </h1>
      <div class="box_button_brifing_full_pages">
        <div class="box_button_v2">
          <button class="button_v1">Оставить заявку</button>
        </div>
      </div>
      <p class="medium_text text_position_services">
        Комплексное SEO для привлечения органического трафика, повышения
        конверсий и вывода вашего сайта в топ поисковых систем.
      </p>
    </div>
  </section>
  <section class="section_advantages_services">
    <div class="container_advantages">
      <h2 class="medium_text text_position_advantages_services">Вывод в топ</h2>
      <img src="@/assets/background/analytics.png" class="img_advantages" />
      <p
        class="font_1_400_Kreadon_Regular text_position_advantages_services_v2"
      >
        Мы работаем над каждым аспектом SEO, чтобы ваш сайт оказался на первых
        страницах поисковых систем.
      </p>
    </div>
    <div class="container_advantages">
      <h2 class="medium_text text_position_advantages_services">
        Привлечение трафика
      </h2>
      <img src="@/assets/background/flexible.png" class="img_advantages" />
      <p
        class="font_1_400_Kreadon_Regular text_position_advantages_services_v2"
      >
        Привлекаем органический трафик за счёт качественной оптимизации контента
        и работы с ключевыми запросами.
      </p>
    </div>
    <div class="container_advantages">
      <h2 class="medium_text text_position_advantages_services">
        Конверсия и ROI
      </h2>
      <img src="@/assets/background/control.png" class="img_advantages" />
      <p
        class="font_1_400_Kreadon_Regular text_position_advantages_services_v2"
      >
        Оптимизируем сайт не только для поисковых систем, но и для повышения
        конверсий и возврата инвестиций.
      </p>
    </div>
    <div class="container_advantages">
      <h2 class="medium_text text_position_advantages_services">
        Локальное SEO
      </h2>
      <img src="@/assets/background/reduction.png" class="img_advantages" />
      <p
        class="font_1_400_Kreadon_Regular text_position_advantages_services_v2"
      >
        Продвигаем ваш бизнес в регионах с помощью оптимизации локального поиска
        и работы с геозависимыми запросами.
      </p>
    </div>
  </section>
  <section class="section_solutions">
    <h2 class="title_services_design color_violet">РЕШЕНИЯ</h2>
    <div class="container_solutions">
      <div
        v-for="(solution, index) in solutions"
        :key="index"
        @click="solution.text && toggleModal(index)"
        :ref="'box_solutions_' + index"
        class="box_solutions"
      >
        <div class="block_solutions">
          <p class="font_1_6_400_Kreadon_Regular">{{ solution.title }}</p>
          <!-- Стрелка отображается только если есть текст -->
          <img
            v-if="solution.text"
            src="@/assets/elements/arrow_href.svg"
            class="arrow_href_img"
          />
        </div>
        <!-- Текстовый блок, который может быть скрыт, если текст отсутствует -->
        <div :ref="'block_max_text_' + index" class="block_max_text">
          <p
            v-if="solution.text"
            class="font_1_400_Kreadon_Regular color_violet"
          >
            {{ solution.text }}
          </p>
        </div>
      </div>
    </div>
  </section>
  <SectionProjectsMain />
  <FooterPages />
</template>
<script>
import HeaderPages from "../permanent/HeaderPages.vue";
import FooterPages from "../permanent/FooterPages.vue";
import SectionProjectsMain from "../ui/SectionProjectsMain.vue";
import { useHead } from "@vueuse/head";

export default {
  components: { HeaderPages, SectionProjectsMain, FooterPages },
  data() {
    return {
      solutions: [
        {
          title: "SEO-оптимизация на этапе разработки",
          text: `Интеграция SEO на этапе создания сайта позволяет заложить правильную
           структуру, улучшить индексацию и обеспечить высокий потенциал
           для последующего продвижения. Мы прорабатываем URL-структуру,
           оптимизируем метатеги и контент под ключевые запросы.`,
        },
        {
          title: "Продвижение молодого сайта",
          text: `Работа с молодыми сайтами требует комплексного подхода. Мы создаём
           стратегию продвижения с учётом конкурентной среды, добавляем контент,
           который отвечает требованиям поисковиков, и привлекаем первые
           органические посетители с помощью качественного линкбилдинга.`,
        },
        {
          title: "Региональное и локальное SEO",
          text: `Региональное SEO помогает вашему бизнесу стать лидером в локальном поиске.
           Мы оптимизируем сайт под геозависимые запросы, регистрируем его в
           локальных каталогах и настраиваем Google My Business, чтобы
           привлечь внимание вашей целевой аудитории.`,
        },
        {
          title: "SEO для интернет-магазинов",
          text: `Продвижение интернет-магазинов включает оптимизацию карточек товаров,
           настройку фильтров и категорий, добавление микроразметки и улучшение
           скорости загрузки страниц. Это помогает улучшить видимость товаров
           и привлечь больше покупателей.`,
        },
        {
          title: "Комплексный аудит и внутренняя оптимизация",
          text: `Комплексный аудит сайта включает в себя проверку технического состояния,
           контентной части и ссылочного профиля. Мы выявляем и устраняем ошибки,
           оптимизируем структуру и навигацию, улучшая общий пользовательский опыт
           и SEO-показатели.`,
        },
        {
          title: "SEO на Tilda и WordPress",
          text: `Сайты на Tilda и WordPress требуют особого подхода. Мы настраиваем
           их для быстрой загрузки, правильной индексации и корректной работы
           SEO-плагинов. Это обеспечивает их успешное продвижение в поисковых системах.`,
        },
      ],
    };
  },
  methods: {
    toggleModal(index) {
      const box = this.$refs[`box_solutions_${index}`][0];
      const text = this.$refs[`block_max_text_${index}`][0];
      if (box && text) {
        box.classList.toggle("active");
        text.classList.toggle("active");
      }
    },
  },
  setup() {
    useHead({
      title:
        "SEO-оптимизация и продвижение: Вывод сайта в топ, привлечение трафика, повышение конверсий",
      meta: [
        {
          name: "description",
          content:
            "Комплексное SEO-продвижение для вашего бизнеса: оптимизация сайтов на Tilda и WordPress, локальное SEO, продвижение интернет-магазинов и новых сайтов. Повышаем видимость, привлекаем органический трафик и увеличиваем конверсии.",
        },
      ],
    });
  },
};
</script>
<style scoped>
.container_text_full_pages {
  padding-left: 6rem;
}
.section_advantages_services {
  padding: 8rem 1rem 0rem 1rem;
}
.text_position_services {
  bottom: -1rem;
}
.text_position_advantages_services {
  top: 1.5rem;
}
.text_position_advantages_services_v2 {
  bottom: 1rem;
}
@media (max-width: 768px) {
  .text_position_services {
    bottom: -8rem;
  }
  .section_advantages_services {
    padding: 10rem 1rem 0rem 1rem;
  }
  .container_text_full_pages {
    padding: 12rem 2.4rem 0rem 2.4rem;
  }
  .title_home_1 {
    margin: 0px;
  }
  .box_button_brifing_full_pages {
    margin-top: 2rem;
  }
}
</style>
