import { createApp } from "vue";
import { createHead } from "@vueuse/head";
import App from "./App.vue";
import { createWebHistory, createRouter } from "vue-router";
import HomePages from "@/components/HomePages.vue";
import ProjectPages from "@/components/pages/ProjectPages.vue";
import BlogPages from "@/components/pages/BlogPages.vue";
import ArticleTest from "@/components/article/ArticleTest.vue";
import ProjectTest from "@/components/project/ProjectTest.vue";
import ServicesPages from "@/components/pages/ServicesPages.vue";
import SiteServices from "@/components/services/SiteServices.vue";
import DesignCorporateIdentity from "@/components/services/DesignCorporateIdentity.vue";
import ComprehensiveAnalytics from "@/components/services/ComprehensiveAnalytics.vue";
import SEOOptimizationPromotion from "@/components/services/SEOOptimizationPromotion.vue";
import TechnicalSupportService from "@/components/services/TechnicalSupportService.vue";
import ContactsPages from "@/components/pages/ContactsPages.vue";
import Important_steps_in_refining_an_existing_website from "@/components/article/Important_steps_in_refining_an_existing_website.vue";
import How_a_turnkey_online_store_will_increase_your_sales from "@/components/article/How_a_turnkey_online_store_will_increase_your_sales.vue";
import Multi_page_site_or_one_page_site from "@/components/article/Multi_page_site_or_one_page_site.vue";
import How_to_develop_a_corporate_website_that_works_for_business from "@/components/article/How_to_develop_a_corporate_website_that_works_for_business.vue";
import Why_should_choose_landing_development_for_sales from "@/components/article/Why_should_choose_landing_development_for_sales.vue";
import Stages_of_website_development_complete_guide_from_idea_to_launch from "@/components/article/Stages_of_website_development_complete_guide_from_idea_to_launch.vue";
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: "HomePages",
      path: "/",
      component: HomePages,
    },
    {
      name: "ProjectPages",
      path: "/all_project",
      component: ProjectPages,
    },
    {
      name: "BlogPages",
      path: "/blog",
      component: BlogPages,
    },
    {
      name: "ArticleTest",
      path: "/article",
      component: ArticleTest,
    },
    {
      name: "ProjectTest",
      path: "/project",
      component: ProjectTest,
    },
    {
      name: "ServicesPages",
      path: "/services",
      component: ServicesPages,
    },
    {
      name: "ContactsPages",
      path: "/contacts",
      component: ContactsPages,
    },
    {
      name: "SiteServices",
      path: "/site_and_services",
      component: SiteServices,
    },
    {
      name: "DesignCorporateIdentity",
      path: "/Design_and_Corporate_Identity",
      component: DesignCorporateIdentity,
    },
    {
      name: "ComprehensiveAnalytics",
      path: "/Comprehensive_Analytics",
      component: ComprehensiveAnalytics,
    },
    {
      name: "SEOOptimizationPromotion",
      path: "/SEO_Optimization_Promotion",
      component: SEOOptimizationPromotion,
    },
    {
      name: "TechnicalSupportService",
      path: "/Technical_Support_Service",
      component: TechnicalSupportService,
    },

    {
      name: "Important_steps_in_refining_an_existing_website",
      path: "/Important_steps_in_refining_an_existing_website",
      component: Important_steps_in_refining_an_existing_website,
    },
    {
      name: "How_a_turnkey_online_store_will_increase_your_sales",
      path: "/How_a_turnkey_online_store_will_increase_your_sales",
      component: How_a_turnkey_online_store_will_increase_your_sales,
    },
    {
      name: "Multi_page_site_or_one_page_site",
      path: "/Multi_page_site_or_one_page_site",
      component: Multi_page_site_or_one_page_site,
    },
    {
      name: "How_to_develop_a_corporate_website_that_works_for_business",
      path: "/How_to_develop_a_corporate_website_that_works_for_business",
      component: How_to_develop_a_corporate_website_that_works_for_business,
    },
    {
      name: "Why_should_choose_landing_development_for_sales",
      path: "/Why_should_choose_landing_development_for_sales",
      component: Why_should_choose_landing_development_for_sales,
    },
    {
      name: "Stages_of_website_development_complete_guide_from_idea_to_launch",
      path: "/Stages_of_website_development_complete_guide_from_idea_to_launch",
      component:
        Stages_of_website_development_complete_guide_from_idea_to_launch,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // Если пользователь возвращается назад, прокручиваем к сохранённой позиции
      return savedPosition;
    } else {
      // Прокручиваем к началу страницы при переходе на новый маршрут
      return { top: 0 };
    }
  },
});
const app = createApp(App);
const head = createHead();
createApp(App);
app.use(router);
app.use(head);
app.mount("#app");
